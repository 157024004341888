import { View } from "react-native";
import background500 from "src/assets/images/500_background.png";
import useDevice from "src/hooks/useDevice";
import { paddingLarge } from "src/styles/spacing";
import { Image, Text } from "src/swsh-native";
import RoundedButton from "../RoundedButton";

const ErrorComponent = ({ resetError }: { resetError?: () => void }) => {
	const { native } = useDevice();
	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<Image
				alt="500 Image"
				src={background500}
				dim="auto"
				sizes={{
					desktop: "100vw",
					mobile: "100vw",
				}}
				style={{
					flex: 1,
				}}
			/>
			<View
				style={{
					alignItems: "center",
					paddingBottom: paddingLarge,
				}}
			>
				<Text semibold large>
					Something went wrong!
				</Text>
				<Text>We ran into an error</Text>
				{!native && (
					<RoundedButton
						colorVariant="Primary"
						containerStyle={{
							marginTop: paddingLarge,
						}}
						onPress={resetError}
						href="/home"
					>
						Return to safety
					</RoundedButton>
				)}
			</View>
		</View>
	);
};
export default ErrorComponent;
