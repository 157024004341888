"use client";

import { captureException } from "@sentry/browser";
import Error from "next/error";
import { useEffect } from "react";
import ErrorComponent from "src/components/ErrorComponent";

const GlobalError = ({ error }: { error: Error }) => {
	useEffect(() => {
		captureException(error);
	}, [error]);

	return <ErrorComponent />;
};
export default GlobalError;
